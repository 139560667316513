<template>
  <div>
    <CInputGroup class="mb-2">
      <CDropdown variant="input-group">
        <CDropdownToggle color="secondary" variant="outline">
          {{ table.search.column ? table.columns[table.search.column] : 'Suche nach...' }}
        </CDropdownToggle>
        <CDropdownMenu>
          <CDropdownItem @click="$emit('update', {term: table.search.term, column: e})" v-for="e in table.searchable">
            {{ table.columns[e] }}
          </CDropdownItem>
        </CDropdownMenu>
      </CDropdown>
      <CFormInput class="animate__animated animate__fadeIn" ref="searchInput" :value="table.search.term"
                  aria-label="Text input with dropdown button" @input="(event) =>
                      $emit('update', {term: event.target.value, column: table.search.column})"
                  @keyup.enter="$emit('search')" v-if="table.search.column"/>
      <CButton
          class="animate__animated animate__fadeIn"
          v-if="table.search.term"
          type="submit"
          color="primary"
          @click="$emit('search')"
      >
        <CIcon name="cil-magnifying-glass"/>
      </CButton>
    </CInputGroup>
  </div>
</template>

<script>
export default {
  name: "DataSearch",
  props: {
    table: {
      type: Object,
      required: true
    },
  },
  methods: {
    focus() {
      if (this.$refs.searchInput) {
        this.$refs.searchInput.$el.focus()
      }
    }
  }
}
</script>

<style scoped>

</style>
