<template>
  <div :class="`${getClasses()} d-flex align-items-center`">
    <LoadingAnimation /> <slot></slot> <strong style="font-size: 2em" class="ms-3">{{ $t('common.loadingData') }} ...</strong>
  </div>
</template>

<script>
import LoadingAnimation from "@/common/LoadingAnimation";
export default {
  name: "DataLoadingSpinner",
  components: {LoadingAnimation},
  props: {
    class: {
      type: String,
      required: false,
      default: 'p-4'
    }
  },
  methods: {
    getClasses() {
      return this.class
    }
  }
}
</script>
