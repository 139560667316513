<template>
  <div>
    <CRow class="mb-3">
      <CCol>
        <div class="pagination-group">
            <CButton color="primary"
                     :variant="instance.current === instance.first? null : 'outline'"
                     @click.prevent="navigate(instance.previous)"
                     :disabled="instance.current === instance.first || !instance.current">
              <span aria-hidden="true">&laquo;</span>
            </CButton>
          <TransitionGroup name="pagination">
            <CButton color="primary" :key="e === '...'? idx + '_2' : e"
                     :variant="e !== instance.current? 'outline' : null" v-for="(e, idx) in getPages()"
                     @click.prevent="e !== '...'? navigate(e) : null"
                     :disabled="e === '...' " v-if="instance.current">
              {{ e }}
            </CButton>
            <CButton :key="1 + 'ab'" disabled v-else>1</CButton>
            <CButton color="primary" :key="'last_'"
                     :variant="instance.current === instance.last? null : 'outline'"
                     @click.prevent="navigate(instance.next)"
                     :disabled="instance.current === instance.last || !instance.current">
              <span aria-hidden="true">&raquo;</span>
            </CButton>
          </TransitionGroup>
        </div>
      </CCol>
    </CRow>
    <CRow>
      <CCol>
        <p class="text-medium-emphasis mb-0">
          Zeigt von <code>{{ instance.items }}</code> Datensätzen
        </p>
      </CCol>
    </CRow>
  </div>
</template>

<script>
export default {
  name: "Pagination",
  props: {
    instance: {
      type: Object,
      default: {},
      required: true
    },
  },
  methods: {
    navigate(page) {
      this.$emit('navigate', page)
    },
    getPages() {
      let gap = 4
      let pages = []
      let count = 0
      for (let i = this.instance.first; i <= this.instance.last; i++) {
        if (this.instance.first === i || this.instance.last === i) {
          pages.push(i)
          continue
        }
        if ((i + gap) >= this.instance.current && (i - gap) <= this.instance.current) {
          pages.push(i)
          continue
        }
        if (this.instance.current === i) {
          pages.push(i)
          continue
        }
        if ((this.instance.first + gap) >= i) {
          pages.push(i)
          continue
        }
        if ((this.instance.last - gap) <= i) {
          pages.push(i)
          continue
        }
        if ((i <= this.instance.current || i >= this.instance.current) && (pages[pages.length - 1] !== '...')) {
          pages.push('...')
          continue
        }
      }
      return pages
    },
  }
}
</script>
<style scoped>
.pagination-group,
.pagination-group-vertical {
  display: inline-flex;
  vertical-align: middle;
}
.pagination-group > .btn,
.pagination-group-vertical > .btn {
  flex: 1 1 auto;
}
.pagination-group > .btn-check:checked + .btn,
.pagination-group > .btn-check:focus + .btn,
.pagination-group > .btn:hover,
.pagination-group > .btn:focus,
.pagination-group > .btn:active,
.pagination-group > .btn.active,
.pagination-group-vertical > .btn-check:checked + .btn,
.pagination-group-vertical > .btn-check:focus + .btn,
.pagination-group-vertical > .btn:hover,
.pagination-group-vertical > .btn:focus,
.pagination-group-vertical > .btn:active,
.pagination-group-vertical > .btn.active {
  z-index: 1;
}
.pagination-group > .btn:not(:first-child),
.pagination-group > .pagination-group:not(:first-child) {
  margin-left: -1px;
}
.pagination-group > .btn:not(:last-child):not(.dropdown-toggle),
.pagination-group > .pagination-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.pagination-group > .btn:nth-child(n+3),
.pagination-group > :not(.btn-check) + .btn,
.pagination-group > .pagination-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
</style>
