import { buildRoutes } from "@/common/helper"



export default buildRoutes(
	'/admin',
	'event',
	'cil-calendar',
	() => import('@/views/event/Event'),
	() => import('@/views/event/EditEvent'),
)
