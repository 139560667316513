import { buildRoutes } from "@/common/helper"


export default buildRoutes(
  '/admin',
  'location',
  'cil-location-pin',
  () => import('@/views/location/Location'),
  () => import('@/views/location/EditLocation'),
)
