<template>
  <CModal size="lg" alignment="center"
          :visible="visible"
          v-on:update:status="visible = $event">
    <CModalHeader class="bg-primary">
      <CModalTitle class="text-white">
        {{ editing? 'Media - Informationen bearbeiten' : 'Media hinzüfugen' }}
      </CModalTitle>
    </CModalHeader>
    <CModalBody>
      <CRow>
        <CCol>
          <CFormInput v-if="!media['@id']"
              type="file"
              description="Datei hinzufügen"
              @input="(event) => { fileCollection = event.target.files }"
              multiple
              ref="uploadFiles"
          />
          <CImage thumbnail v-else class="card-img-top"
                  :src="mediaServiceUrl + media.contentUrl" alt="media"
          />
        </CCol>
        <CCol v-if="(fileCollection && fileCollection.length === 1) || media['@id']">
          <CForm>
            <CRow class="mb-3">
              <CFormLabel for="inputName" class="col-sm-2 col-form-label">
                Name
              </CFormLabel>
              <CCol :sm="0">
                <input class="form-control" v-model="media.name" id="inputName" ref="inputName" />
              </CCol>
            </CRow>
          </CForm>
        </CCol>
      </CRow>
    </CModalBody>
    <CModalFooter>
      <CButton color="secondary" @click="$emit('close')">
        Close
      </CButton>
      <LoadingButton @click="upload()" :loading="loading">
        {{ editing? $t('commands.save') : $t('commands.add') }}
      </LoadingButton>
    </CModalFooter>
  </CModal>
</template>

<script>
import {mapGetters} from "vuex";
import LoadingButton from "@/common/LoadingButton";

export default {
  name: "NewMediaModal",
  components: {LoadingButton},
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    folder: {
      type: String,
      required: true
    }
  },
  emits: ['reload'],
  data() {
    return {
      mediaServiceUrl: process.env.VUE_APP_API_BASEURL,
      fileCollection: []
    }
  },
  computed: {
    ...mapGetters({
      media: 'media/getItem',
      loading: 'media/isLoading'
    }),
    editing() {
      return !!this.media['@id']
    }
  },
  watch: {
    fileCollection(files) {
      if (files.length === 0) {
        return
      }
      if (files.length === 1) {
        this.media.name = files[0].name
        return
      }
      this.upload()
    },
    visible(val) {
      if (val) {
        this.media.folder = this.folder
        this.fileCollection = []
      }
    }
  },
  methods: {
    upload() {
      if (this.editing) {
        this.$store.dispatch('media/save').then(
            (response) => {
              this.$notify({title: this.$t('notifications.saved'), type: 'success'})
              this.$emit('reload')
              this.$emit('close')
            },
            (error) => {
              console.log(error)
            }
        )
        return
      }
      for (let i = 0; i < this.fileCollection.length; i++) {
        this.$store.dispatch('media/save', this.fileCollection[i]).then(
          (response) => {
            this.$notify({title: this.$t('notifications.saved'), type: 'success'})
            this.$emit('reload')
            this.$emit('close')
          },
          (error) => {
            console.log(error)
          }
        )
      }
    }
  }
}
</script>

<style scoped>

</style>
