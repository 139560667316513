import { createApp } from 'vue'
import { i18n } from './i18n'
import Notifications from '@kyvg/vue3-notification'
import VueSweetalert2 from 'vue-sweetalert2'
import CKEditor from '@ckeditor/ckeditor5-vue'
import App from './App.vue'
import store from './store'
import router from './router'
import axios from 'axios'
import 'animate.css'
import LoadingButton from "@/common/LoadingButton"
import Module from "@/common/Module"
import VueMultiselect from 'vue-multiselect'

import CoreuiVue from '@coreui/vue'
import CIcon from '@coreui/icons-vue'
import { iconsSet as icons } from '@/assets/icons'

const app = createApp(App)
app.use(store)
app.use(router)
app.use(CoreuiVue)
app.provide('icons', icons)
app.component('CIcon', CIcon)
app.use(Notifications)
app.use(VueSweetalert2, {
  confirmButtonColor: '#524b91',
  cancelButtonColor: '#ff7674',
})
app.use(CKEditor)
app.use(i18n)
app.component('LoadingButton', LoadingButton)
app.component('Module', Module)
app.component('Multiselect', VueMultiselect)

// Axios configuration
axios.defaults.baseURL = process.env.VUE_APP_API_BASEURL
axios.interceptors.response.use(response =>
{
  return response
}, async error =>
{
  if (error.response && error.response.status === 401 && router.currentRoute.value.name !== 'Login')
  {
    const originalRequest = error.config
    if (!originalRequest._retry && originalRequest.url !== 'refresh_token')
    {
      originalRequest._retry = true
      return new Promise((resolve, reject) =>
      {
        store.dispatch('authorization/refreshToken').then(
          (response) =>
          {
            originalRequest.headers['Authorization'] = 'Bearer ' + response
            resolve(axios(originalRequest))
          },
          (error) =>
          {
            store.commit('authorization/setAuthorization', null)
            router.push({ name: 'Login' })
            reject(error)
          }
        )
      })
    }
    store.commit('authorization/setAuthorization', null)
    router.push({ name: 'Login' })
    return Promise.reject(error)
  }
  return Promise.reject(error)
})

app.mount('#app')
