<template>
	<CDropdown>
		<CDropdownToggle placement="bottom-end" class="py-0" :caret="true">
			{{ authorized.firstname }} {{ authorized.lastname }}
			<CAvatar color="light" size="md" class="ms-2">
				<CIcon name="cil-user" />
			</CAvatar>
		</CDropdownToggle>
		<CDropdownMenu class="pt-0 pb-0 animate__animated animate__fadeIn animate__faster">
			<CDropdownHeader component="h6" class="bg-light fw-semibold py-2">
				Konto
			</CDropdownHeader>
			<CDropdownItem component="button" class="py-2" @click="logout()">
				<CIcon icon="cil-lock-locked" /> Logout
			</CDropdownItem>
			<!-- <CDropdownItem component="button" class="py-2" @click="editProfile()">
				<CIcon icon="cil-user" /> Profile
			</CDropdownItem> -->
		</CDropdownMenu>
	</CDropdown>
</template>

<script>
import avatar from '@/assets/images/avatars/8.jpg'
import { mapGetters } from "vuex"
export default {
	name: 'AppHeaderDropdownAccnt',
	setup () {
		return {
			avatar: avatar,
			itemsCount: 42,
		}
	},
	computed: {
		...mapGetters({
			authorized: 'authorization/getAuthorizedUser'
		})
	},
	methods: {
		logout () {
			this.$notify({ title: 'Sie wurden erfolgreich abgemeldet.', type: 'success' })
			this.$store.dispatch('authorization/logout').then(() => {
				this.$router.push({ path: '/login' })
			})
		},
		// editProfile () {
		// 	this.$router.push({ path: '/user/profile' })
		// }
	}
}
</script>
