import axios from 'axios'

const state = {
    data: {},
    loading: false,
    error: null,
}

const endpoint = '/api/elastic'

const mutations = {
    set: (state, [key, value]) => {
        state[key] = value
    },
}

const actions = {
    info({commit, rootState}, payload) {
        if (!(payload && payload.silent)) {
            commit('set', ['loading', true])
        }

        return new Promise((resolve, reject) => {
            axios
                .get(endpoint + '/info')
                .then(
                    (response) => {
                        commit('set', ['data', response.data])
                        resolve()
                    },
                    (error) => {
                        commit('set', ['error', error])
                        reject(error)
                    },
                )
                .finally(() => {
                    if (!(payload && payload.silent)) {
                        commit('set', ['loading', false])
                    }
                })
        })
    },
}

const getters = {
    getData: state => state.data,
    getError: state => state.error,
    isLoading: state => state.loading,
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}
