import { buildRoutes } from "@/common/helper"



export default buildRoutes(
  '/admin',
  'group',
  'cil-object-group',
  () => import('@/views/group/Group'),
  () => import('@/views/group/EditGroup'),
)
