<template>
	<CRow class="animate__animated animate__fadeIn">
		<CCol sm="7">
			<CCard>
				<CCardHeader class="d-flex flex-row-reverse">
					<CButtonGroup>
						<CButton color="danger" @click="$router.go(-1)">
							<CIcon name="cil-x" /> Abbrechen
						</CButton>
						<LoadingButton :loading="loading" class="px-3" @click="save()" icon="cil-check"
							label="Speichern" />
					</CButtonGroup>
				</CCardHeader>
				<CCardBody>
					<Transition name="fade" mode="out-in">
						<DataLoadingSpinner v-if="loading" />
						<CForm class="px-4 py-3" v-else>
							<CRow class="mb-3">
								<CFormLabel for="inputName" class="col-sm-2 col-form-label">
									Name
								</CFormLabel>
								<CCol :sm="0">
									<CFormInput id="inputName" v-model="company.name" />
								</CCol>
							</CRow>
							<CRow class="mb-3">
								<CFormLabel for="inputRoles" class="col-sm-2 col-form-label">
								</CFormLabel>
								<CCol :sm="10">
									<CFormSwitch label="Premium Account" id="formSwitchCheckChecked" size="xl"
										v-model="company.premiumAccount" :checked="company.premiumAccount" />
								</CCol>
							</CRow>
							<CRow class="mb-3">
								<TextTypeInput label="forms.common.slug" v-model="company.slug" field="slug"
									input-classes="col-sm-10"
									:placeholder="$t? $t('forms.common.messages.emptySlug') : ''" />
							</CRow>
							<template v-for="e in [
							    {key: 'imprint', label: 'Impressum'},
							    {key: 'privacy', label: 'Datenschutz'},
							]">
								<CRow class="mb-3">
									<CFormLabel for="inputName" class="col-sm-2 col-form-label">
										{{ e.label }}
									</CFormLabel>
									<CCol :sm="0">
										<Multiselect size="sm" :block-keys="['Tab', 'Enter']" :allow-empty="false"
											select-label="" selected-label="" deselect-label=""
											v-model="company[e.key].type" :customLabel="(e) => getTypeLabel(e)"
											:options="['text', 'external_link']">
										</Multiselect>
									</CCol>
								</CRow>
								<CRow class="mb-5">
									<CFormLabel :for="'input' + e.key" class="col-sm-2 col-form-label">
									</CFormLabel>
									<CCol :sm="10">
										<ckeditor :editor="editor" v-model="company[e.key].content"
											:config="editorConfig" v-if="company[e.key].type === 'text'" />
										<CFormInput :id="'input' + e.key" v-model="company[e.key].content"
											v-if="company[e.key].type === 'external_link'" />
									</CCol>
								</CRow>
							</template>
							<CRow class="mb-3">
								<CFormLabel for="inputName" class="col-sm-2 col-form-label">
									Logo
								</CFormLabel>
								<CCol sm="5">
									<SingleMediaSelector :media="company.logo"
										@open-selector="() => { this.mediaModal = true }"
										@clear-selection="() => { this.company.logo = null }" />
								</CCol>
							</CRow>
							<CRow class="mb-3">
								<CFormLabel class="col-sm-2 col-form-label">
									{{ $t? $t('forms.company.groups') : '' }}
								</CFormLabel>
								<CCol class="col-sm-10">
									<GroupSelector v-model="company.groups" multiple />
								</CCol>
							</CRow>
						</CForm>
					</Transition>
				</CCardBody>
			</CCard>
		</CCol>
	</CRow>
	<MediaSelectionModal :visible="mediaModal" @close="() => { mediaModal = false }" @select="selectMedia($event)" />
</template>

<script>
import { mapGetters } from "vuex"
import DataLoadingSpinner from "@/common/DataLoadingSpinner"
import { retrieveErrorFromResponse } from "@/common/helper"

import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import MediaSelectionModal from "@/views/media/MediaSelectionModal"
import GroupSelector from "@/views/group/GroupSelector"
import SingleMediaSelector from "@/common/forms/SingleMediaSelector"
import TextTypeInput from "@/common/forms/TextTypeInput"

export default {
	name: "EditCompany",
	components: { TextTypeInput, SingleMediaSelector, GroupSelector, MediaSelectionModal, DataLoadingSpinner },
	computed: {
		...mapGetters({
			company: 'company/getItem',
			loading: 'company/isLoading',
			error: 'company/getError'
		})
	},
	data () {
		return {
			editor: ClassicEditor,
			editorConfig: {},
			mediaModal: false,
		}
	},
	watch: {
		error (error) {
			this.$notify(retrieveErrorFromResponse(error, this.$t))
		}
	},
	created () {
		this.$store.dispatch('company/init')
		if (this.$route.params.id) {
			this.$store.dispatch('company/get', this.$route.params.id).then(() => {
				if (this.company.imprint.length === 0) {
					this.company.imprint = {}
				}
				if (this.company.privacy.length === 0) {
					this.company.privacy = {}
				}
			})
		}
	},
	methods: {
		save () {
			this.$store.dispatch('company/save').then(
				(response) => {
					this.$notify({ title: 'Daten wurden gespeichert', type: 'success' })
					this.$router.push({ name: 'company' })
				})
		},
		getTypeLabel (type) {
			switch (type) {
				case 'external_link':
					return 'Externe Verlinkung'
				case 'text':
					return 'Text'
				default:
					return type
			}
		},
		selectMedia (media) {
			this.mediaModal = false
			this.company.logo = media
		}
	}
}
</script>

<style scoped>

</style>
