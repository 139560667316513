<template>
  <CButton :color="color" :class="getClasses()" type="button" :disabled="loading">
    <CIcon :name="icon" v-if="icon && !loading"/>
    <CSpinner size="sm" v-if="loading"/>
    <slot></slot>
    {{ label }}
  </CButton>
</template>

<script>
export default {
  name: "LoadingButton",
  props: {
    label: {
      type: String,
      required: false,
      default: ''
    },
    loading: {
      type: Boolean,
      required: true,
      default: false
    },
    class: {
      type: String,
      require: false,
      default: 'px-4'
    },
    color: {
      type: String,
      require: false,
      default: 'primary'
    },
    icon: {
      type: String,
      require: false,
    }
  },
  methods: {
    getClasses() {
      return this.class
    }
  }
}
</script>

<style scoped>

</style>
