import axios from 'axios'
import { getPaginationDetails } from "@/common/helper"

const state = {
	collection: {},
	item: {},
	loading: false,
	error: null,
	userCompanies: {},
}

const mutations = {
	set: (state, [key, value]) => {
		state[key] = value
	},
}

const actions = {
	init ({ commit, rootState }, payload) {
		commit('set', ['item', {
			roles: ['ROLE_USER'],
			registration: true
		}])
	},
	get ({ commit, rootState }, payload) {
		commit('set', ['loading', true])

		return new Promise((resolve, reject) => {
			axios
				.get('/api/user/' + payload)
				.then(
					(response) => {
						commit('set', ['item', response.data])
						resolve()
					},
					(error) => {
						commit('set', ['error', error])
						reject(error)
					},
				)
				.finally(() => {
					commit('set', ['loading', false])
				})
		})
	},
	getAll ({ commit, rootState }, payload) {
		commit('set', ['loading', true])

		return new Promise((resolve, reject) => {
			axios
				.get('/api/user', { params: payload })
				.then(
					(response) => {
						commit('set', ['collection', response.data])
						resolve()
					},
					(error) => {
						commit('set', ['error', error])
						commit('set', ['collection', []])
						reject(error)
					},
				)
				.finally(() => {
					commit('set', ['loading', false])
				})
		})
	},
	save ({ commit, rootState }, payload) {
		commit('set', ['loading', true])

		return new Promise((resolve, reject) => {
			if (state.item['@id']) {
				axios
					.patch(state.item['@id'], state.item, { headers: { 'Content-Type': 'application/merge-patch+json' } })
					.then(
						(response) => {
							resolve(response)
						},
						(error) => {
							commit('set', ['error', error])
							reject(error)
						},
					)
					.finally(() => {
						commit('set', ['loading', false])
					})
			} else {
				axios
					.post('/api/user', state.item)
					.then(
						(response) => {
							resolve()
						},
						(error) => {
							commit('set', ['error', error])
							reject(error)
						},
					)
					.finally(() => {
						commit('set', ['loading', false])
					})
			}
		})
	},
	remove ({ commit, rootState }, payload) {
		commit('set', ['loading', true])

		return new Promise((resolve, reject) => {
			axios
				.delete('/api/user/' + payload.id)
				.then(
					(response) => {
						resolve()
					},
					(error) => {
						commit('set', ['error', error])
						reject(error)
					},
				)
				.finally(() => {
					commit('set', ['loading', false])
				})
		})
	},
	userCompanies ({ commit, rootState }, payload) {
		commit('set', ['loading', true])
		return new Promise((resolve, reject) => {

			axios
				.get('/api/user/' + rootState.authorization.authorized.id + '/companies', { params: payload })
				.then(
					(response) => {
						commit('set', ['userCompanies', response.data])
						resolve()
					},
					(error) => {
						commit('set', ['error', error])
						reject(error)
					},
				)
				.finally(() => {
					commit('set', ['loading', false])
				})
		})
	},
	// updateProfile ({ commit, rootState }, payload) {
	// 	commit('set', ['loading', true])

	// 	return new Promise((resolve, reject) => {
	// 		axios
	// 			.patch('/api/user/' + rootState.authorization.authorized.id, { params: payload }, { headers: { 'Content-Type': 'application/merge-patch+json' } })
	// 			.then(
	// 				(response) => {
	// 					resolve()
	// 				},
	// 				(error) => {
	// 					commit('set', ['error', error])
	// 					reject(error)
	// 				},
	// 			)
	// 			.finally(() => {
	// 				commit('set', ['loading', false])
	// 			})
	// 	})
	// }
}

const getters = {
	getUser: state => state.item,
	getError: state => state.error,
	isLoading: state => state.loading,
	getCollection: state => {
		if (state.collection['hydra:member']) {
			return state.collection['hydra:member']
		}
		return []
	},
	getPagination: state => {
		if (state.collection['hydra:view']) {
			return getPaginationDetails(state.collection, '/api/user')
		}
		return {}
	},
	getUserCompanies: state => state.userCompanies['hydra:member'],
}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
}
