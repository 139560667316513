<template>
	<CModal :visible="visible" @close="() => { this.visible = false }" @show="() => { $refs.inputName.focus() }"
		alignment="center">
		<CModalHeader class="bg-primary">
			<CModalTitle class="text-white">{{ this.mediaFolder.id ? 'Ordner - Informationen bearbeiten' :
					'Ordner erstellen'
			}}</CModalTitle>
		</CModalHeader>
		<CModalBody>
			<CForm class="px-4 py-3" @submit.prevent="saveFolder()">
				<CRow class="mb-3">
					<CFormLabel for="inputName" class="col-sm-2 col-form-label">
						Name
					</CFormLabel>
					<CCol :sm="0">
						<input class="form-control" v-model="this.name" id="inputName" ref="inputName" />
					</CCol>
				</CRow>
				<CRow class="mb-3">
					<CFormLabel for="inputName" class="col-sm-3 col-form-label">
						Company
					</CFormLabel>
					<CCol :sm="0">
						<CFormSelect aria-label="Related Company" v-model="this.mediaFolder.owner"
							:disabled="newSubFolder">
							<option disabled>Please select: </option>
							<option v-if="isAdmin" :value="null">No comapny related</option>
							<option v-if="isAdmin" v-for="company in this.getAllComapnies" :value="company['@id']"> {{
									company.name
							}}
							</option>
							<option v-else v-for="company in this.getCompanies" :value="company['@id']">
								{{
										company.name
								}}
							</option>

						</CFormSelect>
					</CCol>
				</CRow>
			</CForm>
		</CModalBody>
		<CModalFooter>
			<CButton color="secondary" @click="() => { $emit('close'); this.name = '' }">
				Close
			</CButton>
			<CButton color="primary" type="submit" @click="saveFolder()">
				{{ this.mediaFolder.id ? 'Speichern' : 'Hinzufügen' }}
			</CButton>
		</CModalFooter>
	</CModal>
</template>

<script>
import { mapGetters } from "vuex"

export default {
	name: "newFolderModal",
	props: {
		visible: {
			type: Boolean,
			required: true
		},
		parent: {
			type: Number,
			required: false,
			default: null
		}

	},
	emits: ['reload', 'close'],
	data () {
		return {
			name: null,
			owner: null,
		}
	},
	created () {
		this.$store.dispatch('user/userCompanies', { 'pagination': false })
		if (this.isAdmin) {
			this.$store.dispatch('company/getAll', { 'pagination': false, 'order[name]': 'asc' })
		}
	},
	watch: {
		visible (val) {
			if (val) {
				if (this.mediaFolder !== null && this.mediaFolder.id) {
					this.name = this.mediaFolder.name
					this.owner = this.mediaFolder.owner
				} else {
					this.mediaFolder.parent = this.parent
				}
			} else {
                this.name = null
                this.owner = null
            }
		}
	},
	computed: {
		...mapGetters({
			mediaFolder: 'media_folder/getItem',
			getCompanies: 'user/getUserCompanies',
			isAdmin: 'authorization/isAdmin',
			getAllComapnies: 'company/getCollection',
		}),
		newSubFolder () {
			return this.mediaFolder.hasOwnProperty('id') || this.mediaFolder.hasOwnProperty('owner')
		}
	},
	methods: {
		saveFolder () {
			this.$store.dispatch('media_folder/save', { owner: this.mediaFolder.owner, name: this.name }).then(
				(response) => {
					this.$notify({ title: this.$t('notifications.saved'), type: 'success' })
					this.$emit('reload')
					this.$emit('close')
				}
			)
			this.parent.name = this.name
			this.parent.owner = this.owner
		}
	}
}
</script>
