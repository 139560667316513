<template>
	<CRow class="p-3">
		<CCol sm="3">
			<CCard v-if="!isLoadingMediaFolder">
				<CCardheader v-if="!mediaSelection">
					<span class="text-muted small">Ordner aktionen: </span>
					<CButtonGroup class="d-flex mt-1">
						<CButton type="button" variant="ghost" color="primary" @click="openNewFolderModal()" size="sm"
							class="p-1">
							<CIcon name="cil-folder" /> Erstellen
						</CButton>
						<CButton type="button" variant="ghost" color="primary" @click="editFolderModal()"
							v-if="selectedFolder" size="sm" class="p-1">
							<CIcon name="cil-pencil" /> Bearbeiten
						</CButton>
						<CButton type="button" variant="ghost" color="danger" @click="deleteFolder()"
							v-if="selectedFolder" size="sm" class="p-1">
							<CIcon name="cil-trash" /> Löschen
						</CButton>

						<CButton type="button" variant="ghost" color="danger" @click="deselectFolder()"
							v-if="selectedFolder" size="sm" class="p-1">
							Abwählen
						</CButton>
					</CButtonGroup>
				</CCardheader>
				<CCardheader v-else class="ps-2 pe-2">
					<CButtonGroup class="d-flex mt-1">
						<CButton block size="sm" @click="openAddMediaModal()" color="primary" variant="outline">
							<CIcon name="cil-playlist-add" /> Medien hinzufügen
						</CButton>
						<CButton color="primary" variant="outline" @click="toggleViewType()" class="px-1">
							<CIcon name="cil-grid" v-if="viewType === 'table'" />
							<CIcon name="cil-list" v-else />
						</CButton>
						<CButton block size="sm" @click="zoomIn()" color="primary" variant="outline" class="px-1">
							<CIcon name="cil-zoom-in" />
						</CButton>
						<CButton block size="sm" @click="zoomOut()" color="primary" variant="outline" class="px-1">
							<CIcon name="cil-zoom-out" />
						</CButton>
					</CButtonGroup>
				</CCardheader>
				<CCardBody :class="mediaSelection ? 'p-1' : 'p-2'">
					<TreeView :items="modifiedFolders" :is-checkable="false" :hideGuideLines="false"
						v-model:selectedItem="selectedFolder" class="animate__animated animate__fadeIn">
						<template v-slot:item-prepend-icon="treeViewItem">
							<CImage :src="`${require('@/assets/images/icons/icons8-opened-folder-48.png')}`"
								alt="folder" height="20" width="20" class="me-2"
								v-if="selectedFolder && treeViewItem.id === selectedFolder.id" />
							<CImage :src="`${require('@/assets/images/icons/icons8-folder-48.png')}`" alt="folder"
								height="20" width="20" class="me-2" v-else />
						</template>
					</TreeView>
				</CCardBody>

			</CCard>
		</CCol>
		<CCol sm="9" v-if="!isLoadingMediaFolder">
			<template v-if="!isLoadingMedia">
				<template v-if="media && media.length > 0">
					<Transition name="fade" mode="out-in">
						<div v-if="viewType === 'table'">
							<CRow>
								<CCol :sm="viewSize" v-for="(e, index) in media"
									class="p-1 border-light border-bottom file-list-row" @click="selectMedia(e)"
									:key="index + '' + viewSize">
									<CRow>
										<CCol>
											<CContainer class="border-start border-end border-top border-bottom py-1">
												<CImage fluid v-if="e.mimeType !== 'application/pdf'"
													:src="getMediaUrl(e['@id'])" :alt="e['@id']" />
											</CContainer>
										</CCol>
									</CRow>
									<CRow v-if="!mediaSelection">
										<CCol>
											<MediaActions @edit="editMedia(e.id)" @delete="deleteMedia(e.id)" />
										</CCol>
									</CRow>
								</CCol>
							</CRow>
						</div>
						<div v-else-if="viewType === 'list'">
							<CTable responsive hover>
								<CTableBody>
									<CTableRow v-for="(e, index) in media" @click="selectMedia(e)" :key="index"
										style="cursor: pointer">
										<CTableDataCell width="1%">
											<CImage v-if="e.mimeType !== 'application/pdf'" class="card-img-top" :style="`width: ${viewSize * 10 + 10
											}px; height: auto; max-height: ${viewSize * 10 + 10
											}px;`" :src="getMediaUrl(e['@id'])" :alt="e['@id']" />
										</CTableDataCell>
										<CTableDataCell style="vertical-align: middle">
											{{ e.name }}
										</CTableDataCell>
										<CTableDataCell width="20%" v-if="!mediaSelection">
											<MediaActions @edit="editMedia(e.id)" @delete="deleteMedia(e.id)" />
										</CTableDataCell>
									</CTableRow>
								</CTableBody>
							</CTable>
						</div>
					</Transition>
					<CRow class="mt-2">
						<CCol>
							<Pagination :instance="pagination" @navigate="(e) => navigate(e)" />
						</CCol>
					</CRow>
				</template>

				<CCard v-else>
					<CCardBody>
						<h4 v-if="!selectedFolder" class="animate__animated animate__fadeIn text-medium-emphasis">
							Bitte wählen Sie einen Ordner
						</h4>
						<h3 v-else class="animate__animated animate__fadeIn">
							<CIcon name="cil-warning" size="xl" />
							Keine Daten verfügbar
						</h3>
					</CCardBody>
				</CCard>
			</template>

			<CCard v-if="isLoadingMedia" class="animate__animated animate__fadeIn">
				<CCardBody>
					<h3>
						<CSpinner />
						Medien werden geladen
					</h3>
				</CCardBody>
			</CCard>
		</CCol>

		<NewFolderModal :visible="folderModal" @close="
			() =>
			{
				folderModal = false
			}
		" @reload="
	() =>
	{
		loadFolders()
	}
" :parent="selectedFolder ? selectedFolder : null" />
		<NewMediaModal :visible="mediaModal" :folder="selectedFolder['@id']" v-if="selectedFolder" @reload="
			() =>
			{
				loadMedia()
			}
		" @close="
	() =>
	{
		mediaModal = false
	}
" />
	</CRow>
</template>

<script>
import { mapGetters } from 'vuex'
import TreeView from 'vue3-tree-vue'
import NewFolderModal from '@/views/media/NewFolderModal'
import NewMediaModal from '@/views/media/NewMediaModal'
import MediaActions from '@/views/media/MediaActions'
import { getResizedMediaUrl } from '@/common/helper'
import Pagination from '@/common/Pagination'

export default {
	name: 'MediaNavigation',
	components: {
		Pagination,
		MediaActions,
		NewMediaModal,
		NewFolderModal,
		TreeView,
	},
	props: {
		selectingNewFolder: {
			type: Boolean,
			required: false,
			default: false,
		},
		mediaSelection: {
			type: Boolean,
			required: false,
			default: false,
		},
	},
	emits: ['select'],
	data () {
		return {
			selectedFolder: null,
			viewType: 'table',
			viewSize: 2,
			mediaModal: false,
			folderModal: false,
			selectedItem: null
		}
	},
	computed: {
		...mapGetters({
			folders: 'media_folder/getCollection',
			media: 'media/getCollection',
			pagination: 'media/getPagination',
			isLoadingMediaFolder: 'media_folder/isLoading',
			isLoadingMedia: 'media/isLoading',
			isAdmin: 'authorization/isAdmin',
			getCompanies: 'user/getUserCompanies',
		}),
		modifiedFolders () {
            if (this.getCompanies && this.getCompanies.length) {
                const companies = this.getCompanies.map(company => company['@id'])
                return this.folders.filter(folder => {
                    return companies.includes(folder.owner)
                })
            }
            return this.folders
		},
	},
	watch: {
		selectedFolder () {
			if (this.selectedFolder != null) {
				this.loadMedia()
			}
		},
	},
	mounted () {
		this.$store.commit('media/set', ['collection', []])
		this.loadFolders()
	},
	methods: {
		deselectFolder () {
			this.selectedFolder = null
			// set collection on media store to empty array
			this.$store.commit('media/set', ['collection', []])
		},
		navigate (page) {
			let payload = { itemsPerPage: 10, page: page }
			this.loadMedia(payload)
		},
		zoomIn () {
			if (this.viewSize <= 12) {
				this.viewSize++
			}
		},
		zoomOut () {
			if (this.viewSize >= 1) {
				this.viewSize--
			}
		},
		toggleViewType () {
			this.viewType = this.viewType === 'table' ? 'list' : 'table'
			this.$emit('update:view-type', this.viewType)
		},
		editMedia (id) {
			this.$store.dispatch('media/get', id).then(
				(response) => {
					this.mediaModal = true
				},
				(error) => {
					// TODO
				},
			)
		},
		loadMedia (payload) {
			if (!payload) {
				payload = {}
			}
			payload.folder = this.selectedFolder.id
			this.$store.dispatch('media/getAll', payload)
		},
		loadFolders () {
			this.$store.dispatch('media_folder/getAll', {
				'order[name]': 'asc',
				'exists[parent]': false,
				pagination: false,
			})
		},
		openNewFolderModal () {
			if (this.selectedFolder) {
				this.$store.commit('media_folder/set', ['item', { owner: this.selectedFolder.owner }])
				this.folderModal = true
			} else {
				this.$store.dispatch('media_folder/init')
				this.folderModal = true
			}
		},
		editFolderModal () {
			this.$store.commit('media_folder/set', ['item', this.selectedFolder])
			this.folderModal = true
		},
		deleteFolder () {
			if (this.media.length > 0 || this.selectedFolder.children.length > 0) {
				this.$notify({
					title: 'The selected folder is not empty.',
					type: 'warn',
				})
				return
			}
			this.$swal({
				title: 'Are you sure?',
				text: "You won't be able to revert this!",
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Delete',
				showLoaderOnConfirm: true,
				preConfirm: (answer) => {
					return this.$store
						.dispatch('media_folder/remove', { id: this.selectedFolder.id })
						.then((response) => {
							return true
						})
						.catch((error) => {
							let msg = 'Request failed.'
							if (error.response.data) {
								msg = `${error.response.data['hydra:title']}: ${error.response.data['hydra:description']}`
							}
							if (error.response.status === 403) {
								msg += ' You are not authorized to perform this action.'
							}
							this.$swal.showValidationMessage(msg)
						})
				},
				allowOutsideClick: () => !this.$swal.isLoading(),
			}).then((response) => {
				if (response.isConfirmed) {
					this.$notify({ title: 'Daten wurden gelöscht', type: 'success' })
					this.loadFolders()
				}
			})
		},
		openAddMediaModal () {
			if (!(this.selectedFolder && this.selectedFolder.id)) {
				this.$notify({ title: 'Bitte wählen Sie einen Ordner aus.', type: 'warn' })
				return
			}
			this.$store.dispatch('media/init')
			this.mediaModal = true
		},
		deleteMedia (id) {
			this.$swal({
				title: 'Are you sure?',
				text: "You won't be able to revert this!",
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Delete',
				showLoaderOnConfirm: true,
				preConfirm: (answer) => {
					return this.$store
						.dispatch('media/remove', { id: id })
						.then((response) => {
							return true
						})
						.catch((error) => {
							let msg = 'Request failed.'
							if (error.response.data) {
								msg = `${error.response.data['hydra:title']}: ${error.response.data['hydra:description']}`
							}
							if (error.response.status === 403) {
								msg += ' You are not authorized to perform this action.'
							}
							this.$swal.showValidationMessage(msg)
						})
				},
				allowOutsideClick: () => !this.$swal.isLoading(),
			}).then((response) => {
				if (response.isConfirmed) {
					this.$notify({ title: 'Daten wurden gelöscht', type: 'success' })
					this.loadMedia()
				}
			})
		},
		selectMedia (media) {
			if (this.mediaSelection) {
				this.$emit('select', media)
			} else {
				this.$store.dispatch('media/get', media.id).then(() => {
					this.mediaModal = true
				})
			}
		},
		getMediaUrl (iri) {
			return getResizedMediaUrl(iri, 200, 200)
		},
	},
}
</script>

<style scoped>
.file-list-row:hover {
	background-color: #e7e7e7 !important;
	cursor: pointer !important;
}

ul {
	overflow: hidden;
	overflow-y: scroll;
	height: 100vh;
	width: 100%;
}
</style>
