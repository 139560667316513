<template>
  <Multiselect v-model="selected" :customLabel="(e) => e.name" track-by="@id"
               :block-keys="['Tab', 'Enter']"
               placeholder="Tippen um zu suchen" select-label="" selected-label=""
               :deselect-label="multiple? $t('commands.delete') : ''"
               :options="collection" :loading="loading"
               :internal-search="false"
               :close-on-select="!multiple" :clear-on-select="multiple"
               :show-no-results="false" :hide-selected="false"
               :allow-empty="multiple" :multiple="multiple" searchable
               @search-change="asyncFind"
  />
</template>

<script>
import {mapGetters} from "vuex";
import {getRelationIdentifiers} from "@/common/helper";

export default {
  name: "GroupSelector",
  props: {
    modelValue: {
      default: '',
      required: true
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false
    },
    preventLoadingData: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      selected: null,
      alreadyLoaded: false,
    }
  },
  watch: {
    modelValue(val) {
      if (!this.alreadyLoaded) {
        this.selected = val
      }
    },
    selected(val) {
      if (this.multiple) {
        this.$emit('update:modelValue', val? val.map(e => e['@id']) : [])
      } else {
        this.$emit('update:modelValue', val? val['@id'] : null)
      }
    },
  },
  computed: {
    ...mapGetters({
      collection: 'group/getCollection',
      loading: 'group/isLoading'
    }),
  },
  mounted() {
    this.selected = this.modelValue
    this.alreadyLoaded = true
  },
  methods: {
    loadData() {
      if (this.modelValue) {
        this.selected = getRelationIdentifiers(this.multiple, this.modelValue, this.collection)
      }
    },
    asyncFind(query) {
      this.$store.dispatch('group/getAll', {name: query, 'order[name]': 'asc'})
    },
  }
}
</script>

<style scoped>

</style>
