import { buildRoutes } from "@/common/helper"




export default buildRoutes(
	'/admin',
	'content-element',
	'cil-line-style',
	() => import('@/views/content_element/ContentElement'),
	() => import('@/views/content_element/EditContentElement'),
)
