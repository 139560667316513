<template>
	<Module @after-reload="$refs.table.$refs.search? $refs.table.$refs.search.focus() : null">
		<template v-slot:commands>
			<LoadingButton :loading="loading" class="px-2" @click="$refs.table.navigate(1)" label="Neu laden"
				color="light" icon="cil-sync" />
			<CButton color="primary" v-if="isAdmin" @click="$router.push({path: '/admin/company/new'})">
				<CIcon name="cil-playlist-add" /> Hinzufügen
			</CButton>
		</template>
		<DataTable :loading="loading" :table="table" @navigate="$store.dispatch('company/getAll', $event)"
			:pagination="pagination" ref="table">
			<template v-slot:content>
				<TransitionGroup name="table">
					<CTableRow v-for="e in collection" class="text-center" :key="e.id">
						<CTableHeaderCell>{{ e.id }}</CTableHeaderCell>
						<CTableDataCell>
							{{ e.name }}
							<Transition name="fade" mode="out-in">
								<CBadge color="primary" v-if="company && (company['@id'] === e['@id'])" class="ms-1">
									Zurzeit in der Verwaltung
								</CBadge>
							</Transition>
						</CTableDataCell>
						<CTableDataCell>
							<CBadge color="primary" v-if="e.premiumAccount"> PREMIUM </CBadge>
						</CTableDataCell>
						<CTableDataCell class="py-1">
							<CButtonGroup size="sm">
								<TransitionGroup name="fade" mode="out-in">
									<CButton v-if="!(company && (company['@id'] === e['@id']))" color="primary"
										variant="ghost" @click="$store.commit('setCompany', e)" key="btn-1">
										<CIcon name="cil-applications" />
									</CButton>
									<CButton color="primary" variant="ghost" key="btn-2"
										@click="$router.push({path: '/admin/company/edit/' + e.id})">
										<CIcon name="cil-pencil" />
									</CButton>
									<CButton color="danger" variant="ghost" key="btn-2" @click="remove(e.id)">
										<CIcon name="cil-trash" />
									</CButton>
								</TransitionGroup>
							</CButtonGroup>
						</CTableDataCell>
					</CTableRow>
				</TransitionGroup>
			</template>
		</DataTable>
		<template v-slot:footer>
			<CRow>
				<CCol class="d-flex align-items-end justify-content-sm-end">
					<span class="text-muted" v-for="e in [
					    {icon: 'applications', label: 'commands.manage'},
					    {icon: 'pencil', label: 'commands.edit'},
					    {icon: 'trash', label: 'commands.delete'},
					]">
						<CIcon :name="`cil-${e.icon}`" class="ms-3 me-1" /><strong>{{ $t(e.label) }}</strong>
					</span>
				</CCol>
			</CRow>
		</template>
	</Module>
</template>

<script>
import { mapGetters } from "vuex"
import Pagination from "@/common/Pagination"
import DataSearch from "@/common/DataSearch"
import DataTable from "@/common/DataTable"

export default {
	name: 'Company',
	components: { DataTable, DataSearch, Pagination },
	data () {
		return {
			table: {
				columns: {
					'id': 'ID',
					'name': 'Name',
					'premiumAccount': 'Premium',
				},
				searchable: ['id', 'name'],
				sortable: ['id', 'name', 'premiumAccount'],
				sort: {
					column: null,
					direction: 'desc'
				},
				search: {
					term: '',
					column: null
				}
			},
		}
	},
	watch: {
		error (value) {
			this.$notify({ title: 'Error: ' + value, type: 'error' })
		}
	},
	computed: {
		...mapGetters({
			company: 'getCompany',
			loading: 'company/isLoading',
			error: 'company/getError',
			collection: 'company/getCollection',
			pagination: 'company/getPagination',
			isAdmin: 'authorization/isAdmin'
		})
	},
	mounted () {
		this.$store.dispatch('company/getAll')
		this.$store.dispatch('user/userCompanies', { 'pagination': false })
	},
	methods: {
		remove (id) {
			this.$swal({
				title: 'Are you sure?',
				text: "You won't be able to revert this!",
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Delete',
				showLoaderOnConfirm: true,
				preConfirm: (answer) => {
					return this.$store.dispatch('company/remove', { id: id })
						.then((response) => {
							return true
						}).catch((error) => {
							let msg = 'Request failed.'
							if (error.response.data) {
								msg = `${error.response.data['hydra:title']}: ${error.response.data['hydra:description']}`
							}
							if (error.response.status === 403) {
								msg += ' You are not authorized to perform this action.'
							}
							this.$swal.showValidationMessage(msg)
						})
				},
				allowOutsideClick: () => !this.$swal.isLoading()
			}).then((response) => {
				if (response.isConfirmed) {
					this.$notify({ title: 'Daten wurden gelöscht', type: 'success' })
					this.$refs.table.navigateToCurrent()
				}
			})
		}
	}
}
</script>
