<template>
  <CRow :class="$props.class">
    <CCol>
      <CRow v-if="!noSearch">
        <CCol sm="4">
          <DataSearch :table="table" @search="navigate(1)"
                      @update="table.search = $event" ref="search"
          />
        </CCol>
      </CRow>
      <CRow class="overflow-hidden position-relative">
        <Transition name="fade" mode="out-in">
          <div class="bg-secondary position-absolute d-flex justify-content-center bg-opacity-25 align-items-center"
               style="z-index: 10; min-height: 100%!important" v-if="loading"
          >
            <LoadingAnimation />
          </div>
        </Transition>
        <CCol :class="`mt-3 ${loading? ' opacity-75' : ''}`">
          <CTable striped hover responsive>
            <CTableHead>
              <CTableRow>
                <CTableHeaderCell v-for="(e, key) in table.columns"
                                  class="text-center" @click="toggleSort(key)"
                                  :style="table.sortable.includes(key)? 'cursor: pointer' : ''"
                >
                  <template v-if="table.sort.column === key">
                    <Transition name="fade" mode="out-in">
                      <CIcon name="cil-arrow-top" v-if="table.sort.direction === 'asc'"/>
                      <CIcon name="cil-arrow-bottom" v-else/>
                    </Transition>
                  </template>
                  {{ e }}
                </CTableHeaderCell>
                <CTableHeaderCell width="10%" class="text-center" v-if="!noActions">Actions</CTableHeaderCell>
              </CTableRow>
            </CTableHead>
              <slot name="content-t"></slot>
            <CTableBody>
              <slot name="content"></slot>
            </CTableBody>
          </CTable>
        </CCol>
      </CRow>
      <CRow class="mt-2">
        <CCol>
          <Pagination :instance="pagination" @navigate="navigate($event)"/>
        </CCol>
      </CRow>
    </CCol>
  </CRow>

</template>

<script>
import DataSearch from "@/common/DataSearch";
import Pagination from "@/common/Pagination";
import LoadingAnimation from "@/common/LoadingAnimation";
export default {
  name: "DataTable",
  components: {LoadingAnimation, Pagination, DataSearch},
  props: {
    table: {
      type: Object,
      required: true,
    },
    pagination: {
      type: Object,
      required: true,
    },
    'class': {
      type: String,
      required: false,
      default: 'm-4',
    },
    bordered: {
      type: Boolean,
      required: false,
      default: true,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    noActions: {
      type: Boolean,
      required: false,
      default: false,
    },
    noSearch: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    toggleSort(key) {
      if (this.table.sortable.includes(key)) {
        this.table.sort.direction = (this.table.sort.direction === 'asc')? 'desc' : 'asc'
        this.table.sort.column = key
        this.navigate(1)
      }
    },
    navigate(page) {
      let payload = {itemsPerPage: 10, page: page}
      if (this.table.search.column) {
        payload[this.table.search.column] = this.table.search.term
      }
      if (this.table.sort.column) {
        payload[`order[${this.table.sort.column}]`] = this.table.sort.direction
      }
      this.$emit('navigate', payload)
    },
    navigateToCurrent() {
      let page = 1
      if (this.pagination.current) {
        page = this.pagination.current
      }
      this.navigate(page)
    }
  }
}
</script>
<style>
/* 1. declare transition */
.table-move,
.table-enter-active,
.table-leave-active {
  transition: all 0.4s cubic-bezier(.55,.19,.64,.86);
}

.table-leave-from {
  opacity: 0.5;
  transform: translateY(0px);
}

/* 2. declare enter from and leave to state */
.table-enter-from,
.table-leave-to {
  opacity: 0;
  transform: translateX(50%);
}

/* 3. ensure leaving items are taken out of layout flow so that moving
      animations can be calculated correctly. */
.table-leave-active {
  position: absolute;
}
</style>
