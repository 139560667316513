import { buildRoutes } from "@/common/helper"
import store from '@/store'
import Company from '@/views/company/Company'
import EditCompany from '@/views/company/EditCompany'


const icon = 'cil-building'


export default [
	{
		path: '/admin/company',
		name: 'Company',
		meta: {
			icon: icon,
		},
		component: Company,
	},
	{
		path: '/admin/company/new',
		name: 'Create Company',
		meta: {
			icon: icon,
		},
		beforeEnter: (async (to, from, next) => {
			//get the isAdmin state from the authorization file in the store
			const isAdmin = await store.getters['authorization/isAdmin']

			if (!isAdmin) {
				next({ name: 'Company' })
			}
			next()
		}),
		component: EditCompany,
	},
	{
		path: '/admin/company/edit/:id',
		name: 'Edit Company',
		meta: {
			icon: icon,
		},
		beforeEnter: (async (to, from, next) => {

			//get the isAdmin state from the authorization file in the store
			const isAdmin = await store.getters['authorization/isAdmin']
			const userCompanies = await store.getters['user/getUserCompanies']

			if (!isAdmin && userCompanies?.filter(x => x.id == to.params.id).length == 0) {
				next({ name: 'Company' })
			}
			next()
		}),
		component: EditCompany,
	},
]
