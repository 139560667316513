import {createStore} from 'vuex'

import authorization from '@/store/authorization'
import category from '@/store/modules/category'
import company from '@/store/modules/company'
import content_element from '@/store/modules/content_element'
import elastic from '@/store/modules/elastic'
import event from '@/store/modules/event'
import location from '@/store/modules/location'
import group from '@/store/modules/group'
import media from '@/store/modules/media'
import media_folder from '@/store/modules/media_folder'
import news from '@/store/modules/news'
import offer from '@/store/modules/offer'
import region from '@/store/modules/region'
import user from '@/store/modules/user'

const state = {
    sidebarVisible: '',
    sidebarUnfoldable: false,
    selectedCompany: null
}

const mutations = {
    setCompany: (state, value) => {
        state.selectedCompany = value
    },
    toggleSidebar(state) {
        state.sidebarVisible = !state.sidebarVisible
    },
    toggleUnfoldable(state) {
        state.sidebarUnfoldable = !state.sidebarUnfoldable
    },
    updateSidebarVisible(state, payload) {
        state.sidebarVisible = payload.value
    },
}

const actions = {}

const getters = {
    getCompany: state => state.selectedCompany
}

export default createStore({
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
    modules: {
        authorization,
        category,
        company,
        content_element,
        elastic,
        event,
        location,
        group,
        media,
        media_folder,
        news,
        offer,
        region,
        user,
    },
})
