import axios from "axios"

const apiUrl = process.env.VUE_APP_API_BASEURL

const getPaginationPage = (view, parameter, endpoint) =>
{
	if (view[parameter])
	{
		return parseInt((new URLSearchParams(view[parameter].replace(endpoint, ''))).get('page'))
	}
	return null
}

export const retrieveErrorFromResponse = (error, translator) =>
{
	if (!error || !error.response)
	{
		return { title: error, type: 'error' }
	}
	if (error.response.status === 422)
	{
		let msg = '<html>'
		if (error.response && error.response.data && error.response.data.violations)
		{
			error.response.data.violations.forEach(e =>
			{
				msg += translator('error.' + e.code, { field: translator('error.field.' + e.propertyPath) }) + ' <br/>'
			})
		}
		msg += '</html>'
		return { title: 'Fehler beim speichern', type: 'error', text: msg }
	}
	if (error.response.status === 400)
	{
		let data = error.response.data
		if (data['hydra:title'])
		{
			return { title: data['hydra:title'], type: 'error', text: data['hydra:description'] }
		}
	}
	if (error.response.status === 403)
	{
		let data = error.response.data
		if (data['hydra:title'])
		{
			return { title: data['hydra:title'], type: 'error', text: 'The current user is not authorized to perform this action. ' + data['hydra:description'] }
		}
	}
	return { title: error, type: 'error' }
}

export const getPaginationDetails = (collection, endpoint) =>
{
	if (!collection['hydra:view'])
	{
		if (collection['hydra:totalItems'])
		{
			return { items: parseInt(collection['hydra:totalItems']) }
		}
		return {}
	}
	let view = collection['hydra:view']
	return {
		current: getPaginationPage(view, '@id', endpoint),
		first: getPaginationPage(view, 'hydra:first', endpoint),
		last: getPaginationPage(view, 'hydra:last', endpoint),
		previous: getPaginationPage(view, 'hydra:previous', endpoint),
		next: getPaginationPage(view, 'hydra:next', endpoint),
		items: parseInt(collection['hydra:totalItems'])
	}
}

export const buildRoutes = (prefix, module, icon, indexComponent, editComponent, authorized) =>
{
	return [
		{
			path: prefix + '/' + module,
			name: module,
			meta: {
				icon: icon,
			},
			component: indexComponent,
		},
		{
			path: prefix + '/' + module + '/new',
			name: 'Create ' + module,
			meta: {
				icon: icon,
			},
			component: editComponent,
		},
		{
			path: prefix + '/' + module + '/edit/:id',
			name: 'Edit ' + module,
			meta: {
				icon: icon,
			},
			component: editComponent,
		}
	]
}

export const getDeleteOperation = (endpoint, id, commit) => new Promise((resolve, reject) =>
{
	axios
		.delete(endpoint + '/' + id)
		.then(
			(response) =>
			{
				resolve()
			},
			(error) =>
			{
				commit('set', ['error', error])
				reject(error)
			},
		)
})

export const deleteDispatcher = (module, dispatcher, payload, notifier) => dispatcher(module + '/remove', payload)
	.then((response) =>
	{
		return true
	}).catch((error) =>
	{
		let msg = 'Request failed.'
		if (error.response && error.response.data)
		{
			msg = `${error.response.data['hydra:title']}: ${error.response.data['hydra:description']}`
		}
		if (error.response && error.response.status === 403)
		{
			msg += ' You are not authorized to perform this action.'
		}
		notifier(msg)
	})

export const getGetters = (endpoint) =>
{
	return {
		getItem: state => state.item,
		getError: state => state.error,
		isLoading: state => state.loading,
		getCollection: state =>
		{
			if (state.collection['hydra:member'])
			{
				return state.collection['hydra:member']
			}
			return []
		},
		getPagination: state => getPaginationDetails(state.collection, endpoint)
	}
}

export const getRelationIdentifiers = (multiple, value, options) =>
{
	if (multiple)
	{
		return options.filter(e =>
		{
			let array = value.map(function (f)
			{
				if (typeof f === 'string')
				{
					return f
				}
				return f['@id']
			})
			return array.includes(e['@id'])
		})
	} else
	{
		if (typeof value === 'string')
		{
			return options.find(e => e['@id'] === value)
		} else
		{
			return options.find(e => e['@id'] === value['@id'])
		}
	}
}

export const getResizedMediaUrl = (iri, width, height) =>
{
	return apiUrl + iri + '/' + width + '/' + height
}

export const clone = (array) => JSON.parse(JSON.stringify(array))

export const prepareDeleteOperation = (swal, dispatch, type, id) => swal({
	title: 'Are you sure?',
	text: "You won't be able to revert this!",
	icon: 'warning',
	showCancelButton: true,
	confirmButtonText: 'Delete',
	showLoaderOnConfirm: true,
	preConfirm: (answer) =>
	{
		return dispatch(type + '/remove', { id: id })
			.then((response) =>
			{
				return true
			}).catch((error) =>
			{
				let msg = 'Request failed.'
				if (error.response && error.response.data)
				{
					msg = `${error.response.data['hydra:title']}: ${error.response.data['hydra:description']}`
				}
				if (error.response && error.response.status === 403)
				{
					msg += ' You are not authorized to perform this action.'
				}
				swal.showValidationMessage(msg)
			})
	},
	allowOutsideClick: () => !swal.isLoading()
})
