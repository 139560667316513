import { buildRoutes } from "@/common/helper"
import store from '@/store'

const category = () => import('@/views/category/Category')
const editCategory = () => import('@/views/category/EditCategory')
const icon = 'cil-star'


// export default buildRoutes(
//   '/admin',
//   'category',
//   'cil-star',
//   () => import('@/views/category/Category'),
//   () => import('@/views/category/EditCategory'),
// )

export default [
  {
    path: '/admin/category',
    name: 'Category',
    meta: {
      icon: icon,
    },
	beforeEnter: (async (to, from, next) =>
    {
      //get the isAdmin state from the authorization file in the store
      const isAdmin = await store.getters['authorization/isAdmin']

      if (!isAdmin)
      {
        next({ name: 'Category' })
      }
      next()
    }),
    component: category,
  },
  {
    path: '/admin/category/new',
    name: 'Create Category',
    meta: {
      icon: icon,
    },
    beforeEnter: (async (to, from, next) =>
    {
      //get the isAdmin state from the authorization file in the store
      const isAdmin = await store.getters['authorization/isAdmin']

      if (!isAdmin)
      {
        next({ name: 'Category' })
      }
      next()
    }),
    component: editCategory,
  },
  {
    path: '/admin/category/edit/:id',
    name: 'Edit Category',
    meta: {
      icon: icon,
    },
    beforeEnter: (async (to, from, next) =>
    {
      //get the isAdmin state from the authorization file in the store
      const isAdmin = await store.getters['authorization/isAdmin']

      if (!isAdmin)
      {
        next({ name: 'Category' })
      }
      next()
    }),
    component: editCategory,
  },
]

