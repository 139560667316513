<template>
  <CContainer class="border-start border-end border-top border-bottom py-1" v-if="media">
    <CImage fluid :src="getMediaUrl()" alt="logo"/>
  </CContainer>
  <CButtonGroup class="d-flex mt-1">
    <CButton color="primary" type="button" variant="outline" @click="this.$emit('open-selector')" class="mt-1">
      <CIcon name="cil-touch-app"/>
      {{ $t('commands.select') }}
    </CButton>
    <CButton color="danger" variant="outline" @click="$emit('clear-selection')"
             class="mt-1" v-if="media">
      <CIcon name="cil-x"/>
      {{ $t('commands.delete') }}
    </CButton>
  </CButtonGroup>
</template>

<script>
import {getResizedMediaUrl} from "@/common/helper";

export default {
  name: "SingleMediaSelector",
  emits: ['open-selector', 'clear-selection'],
  props: {
    media: {
      type: Object,
      required: false,
      default: null
    }
  },
  methods: {
    getMediaUrl() {
      return getResizedMediaUrl(this.media['@id'], 300, 300)
    }
  }
}
</script>

<style scoped>

</style>
