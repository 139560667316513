import axios from 'axios'
import jwtDecode from "jwt-decode"

const state = {
	loading: false,
	error: null,
	authorization: null,  // contains the jwt token and refresh token
	authorized: {},
	isAdmin: null,
}

const mutations = {
	set: (state, [key, value]) => {
		state[key] = value
	},
	setAuthorization: (state, authorization) => {
		if (authorization !== null && authorization !== {}) {
			let token = authorization.token
			let refreshToken = authorization.refresh_token
			if (authorization.token) {
				state.authorization = authorization.token
				axios.defaults.headers.common['Authorization'] = 'Bearer ' + authorization.token
			}
			sessionStorage.setItem('authorization', JSON.stringify({ token: token, refresh_token: refreshToken }))
		} else {
			sessionStorage.removeItem('authorization')
			state.authorization = null
			state.authorized = {}
			axios.defaults.headers.common['Authorization'] = undefined
		}
	},
	setUserRole: (state, isAdmin) => {
		if (isAdmin !== null && isAdmin !== {}) {
			state.isAdmin = isAdmin
		}
	}
}

const actions = {
	//checking authorization function using jwt token stored in local storage
	checkAuthorization: ({ commit, state }) => {
		if (state.authorization) {
			const token = state.authorization
			const decoded = jwtDecode(token)
			const isAdmin = decoded.roles.includes('ROLE_ADMIN')
			const userData = decoded.user
			let now = new Date()
			let exp = new Date(decoded.exp * 1000)
			if (exp < now) {
				// token expired
				commit('setAuthorization', null)
			} else {
				// token not expired
				commit('set', ['authorized', userData])
				commit('setUserRole', isAdmin)
			}
		}
	},


	oldCheckAuthorization ({ commit, rootState, dispatch }, payload) {
		return new Promise((resolve, reject) => {
			let authorization = jwtDecode(state.authorization)
			axios.get(authorization.user['@id']).then(
				(response) => {
					if (!response) {
						throw false
					}
					if (response.data && response.data.registration) {
						resolve({ user: authorization.user, changePassword: true })
						return
					}
					if (response.data) {
						commit('set', ['authorized', response.data])
						commit('setUserRole', response.data.admin)
					}
				},
				(error) => {
					dispatch('logout')
				}
			)
		})
	},


	refreshToken ({ commit, rootState, dispatch }) {
		return new Promise((resolve, reject) => {
			let authorization = sessionStorage.getItem('authorization')
			authorization = JSON.parse(authorization)

			if (!authorization.refresh_token) {
				reject(resolve)
			}

			axios.post('refresh_token', {
				refresh_token: authorization.refresh_token
			}).then(
				(response) => {
					if (response.data) {
						commit('setAuthorization', response.data)
					}
					resolve(response.data.token)
				},
				(error) => {
					reject(error)
				}
			)
		})
	},
	login ({ commit, rootState }, payload) {
		commit('set', ['loading', true])
		return new Promise((resolve, reject) => {
			axios
				.post('/authentication_token', {
					email: payload.email,
					password: payload.password,
				})
				.then(
					(response) => {
						commit('setAuthorization', response.data)
						resolve()
					},
					(error) => {
						commit('set', ['error', error])
						reject(error)
					},
				)
				.finally(() => {
					commit('set', ['loading', false])
				})
		})
	},
	logout ({ commit, rootState }, payload) {
		return new Promise((resolve, reject) => {
			commit('setAuthorization', null)
			resolve()
		})
	}
}

const getters = {
	isLoading (state) { return state.loading },
	getAuthorized: (state) => !!state.authorized['id'],
	getAuthorizedUser: (state) => state.authorized, //returns the logged in user
	isAdmin: (state) => state.isAdmin,
}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
}
