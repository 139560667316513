import axios from 'axios'
import { getPaginationDetails } from "@/common/helper"

const state = {
	collection: {},
	item: {},
	loading: false,
	error: null,
}

const endpoint = '/api/company'

const mutations = {
	set: (state, [key, value]) => {
		state[key] = value
	},
}

const actions = {
	init ({ commit, rootState }, payload) {
		commit('set', ['item', {
			imprint: {},
			privacy: {},
			premiumAccount: false,
			group: null,
		}])
	},
	get ({ commit, rootState }, payload) {
		commit('set', ['loading', true])

		return new Promise((resolve, reject) => {
			axios
				.get(endpoint + '/' + payload)
				.then(
					(response) => {
						commit('set', ['item', response.data])
						resolve()
					},
					(error) => {
						commit('set', ['error', error])
						reject(error)
					},
				)
				.finally(() => {
					commit('set', ['loading', false])
				})
		})
	},
	getAll ({ commit, rootState }, payload) {
		commit('set', ['loading', true])

		return new Promise((resolve, reject) => {
			let config = { params: payload }
			if (payload && payload.format && payload.format === 'excel') {
				config.responseType = 'blob'
			}

			axios
				.get(endpoint, config)
				.then(
					(response) => {
						if (config.responseType === 'blob') {
							resolve(response)
						} else {
							commit('set', ['collection', response.data])
							resolve(response)
						}
					},
					(error) => {
						commit('set', ['error', error])
						commit('set', ['collection', []])
						reject(error)
					},
				)
				.finally(() => {
					commit('set', ['loading', false])
				})
		})
	},
	save ({ commit, rootState }, payload) {
		commit('set', ['loading', true])

		let object = {}
		Object.assign(object, state.item)
		if (object.logo) {
			object.logo = object.logo['@id']
		}

		return new Promise((resolve, reject) => {
			if (state.item['@id']) {
				axios
					.patch(state.item['@id'], object, { headers: { 'Content-Type': 'application/merge-patch+json' } })
					.then(
						(response) => {
							resolve(response)
						},
						(error) => {
							commit('set', ['error', error])
							reject(error)
						},
					)
					.finally(() => {
						commit('set', ['loading', false])
					})
			} else {
				axios
					.post(endpoint, object)
					.then(
						(response) => {
							resolve(response)
						},
						(error) => {
							commit('set', ['error', error])
							reject(error)
						},
					)
					.finally(() => {
						commit('set', ['loading', false])
					})
			}
		})
	},
	remove ({ commit, rootState }, payload) {
		return new Promise((resolve, reject) => {
			axios
				.delete(endpoint + '/' + payload.id)
				.then(
					(response) => {
						resolve()
					},
					(error) => {
						commit('set', ['error', error])
						reject(error)
					},
				)
		})
	},
}

const getters = {
	getItem: state => state.item,
	getError: state => state.error,
	isLoading: state => state.loading,
	getCollection: state => {
		if (state.collection['hydra:member']) {
			return state.collection['hydra:member']
		}
		return []
	},
	getPagination: state => getPaginationDetails(state.collection, endpoint)
}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
}
