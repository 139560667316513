<template>
  <CHeader position="sticky" class="mb-4">
    <CContainer fluid>
      <CHeaderToggler class="ps-1" @click="$store.commit('toggleSidebar')">
        <CIcon icon="cil-menu" size="lg" />
      </CHeaderToggler>
      <Transition name="fade" mode="out-in">
        <CHeaderBrand v-if="company !== null" :key="company.name">
          <small class="text-medium-emphasis">Sie verwalten jetzt das Unternehmen:</small>
          <strong class="ms-2">{{ company.name }}</strong>
          <CButtonGroup>
            <CButton variant="ghost" color="primary"
                     size="sm" class="ms-2" v-if="$route.name !== 'Dashboard'"
                     @click="$router.push({name: 'Dashboard'})"
            >
              <CIcon name="cil-swap-horizontal" size="sm" />
            </CButton>
            <CButton variant="ghost" color="danger"
                     size="sm" @click="$store.commit('setCompany', null)"
            >
              <CIcon name="cil-x" size="sm" />
            </CButton>
          </CButtonGroup>
        </CHeaderBrand>
      </Transition>
      <CHeaderBrand class="mx-auto d-lg-none" to="/">
        <CIcon :icon="logo" height="48" alt="Logo" />
      </CHeaderBrand>
      <CHeaderNav>
        <AppHeaderDropdownAccnt />
      </CHeaderNav>
    </CContainer>
    <CHeaderDivider />
    <CContainer fluid>
      <AppBreadcrumb />
    </CContainer>
  </CHeader>
</template>

<script>
import AppBreadcrumb from './AppBreadcrumb'
import AppHeaderDropdownAccnt from './AppHeaderDropdownAccnt'
import { logo } from '@/assets/brand/logo'
import {mapGetters} from "vuex";
export default {
  name: 'AppHeader',
  components: {
    AppBreadcrumb,
    AppHeaderDropdownAccnt,
  },
  computed: {
    ...mapGetters({
      company: 'getCompany'
    })
  },
  setup() {
    return {
      logo,
    }
  },
}
</script>
