<template>
	<CSidebar position="fixed" :unfoldable="sidebarUnfoldable" :visible="sidebarVisible" @visible-change="
	  (event) =>
	    $store.commit({
	      type: 'updateSidebarVisible',
	      value: event,
	    })
	">
		<CSidebarBrand>
			<CIcon custom-class-name="sidebar-brand-full" :icon="logoNegative" :height="50" />
			<CIcon custom-class-name="sidebar-brand-narrow" :icon="logoNegative" :height="35" />
		</CSidebarBrand>
		<AppSidebarNav :nav="navigationTree" :key="key" :locale-function="$t" />
		<CSidebarToggler class="d-none d-lg-flex" @click="$store.commit('toggleUnfoldable')" />
	</CSidebar>
</template>

<script>
import { computed, ref } from 'vue'
import { mapGetters, useStore } from 'vuex'
import { AppSidebarNav } from './AppSidebarNav'
import { logoNegative } from '@/assets/brand/logo-negative'
import { sygnet } from '@/assets/brand/sygnet'

export default {
	name: 'AppSidebar',
	components: {
		AppSidebarNav,
	},
	computed: {
		...mapGetters({
			company: 'getCompany',
			user: 'authorization/getAuthorizedUser',
			isAdmin: 'authorization/isAdmin',
		}),
		navigationTree () {
			let nav = [
				{
					component: 'CNavItem',
					name: 'menu.dashboard',
					to: '/dashboard',
					icon: 'cil-speedometer',
					badge: {
						color: 'primary',
					},
				},
				{
					component: 'CNavTitle',
					name: 'Verwaltung',
				},

				{
					component: 'CNavItem',
					name: 'menu.management.company',
					to: '/admin/company',
					icon: 'cil-building',
				},
				{
					component: 'CNavItem',
					name: 'menu.management.media',
					to: '/admin/media',
					icon: 'cil-library'
				},
			]
			let navT = JSON.parse(JSON.stringify(nav))
			if (this.company) {
				navT.splice(
					1,
					0,
					{
						component: 'CNavTitle',
						name: 'Unternehmen',
					},
					{
						component: 'CNavItem',
						name: 'menu.company.location',
						to: '/admin/location',
						icon: 'cil-location-pin',
					},
					{
						component: 'CNavItem',
						name: 'menu.company.offer',
						to: '/admin/offer',
						icon: 'cil-coffee',
					},
					// {
					//   component: 'CNavItem',
					//   name: 'menu.company.news',
					//   to: '/admin/news',
					//   icon: 'cil-newspaper',
					// },
					{
						component: 'CNavItem',
						name: 'menu.company.content',
						to: '/admin/content-element',
						icon: 'cil-line-style',
					},
				)
			}
			if (this.isAdmin || this.user.companyGroup?.length > 0) {
				navT.splice(
					1,
					0,
					{
						component: 'CNavTitle',
						name: this.$t('menu.management.group'),
					},
					{
						component: 'CNavItem',
						name: 'menu.group.event',
						to: '/admin/event',
						icon: 'cil-calendar',
					},
				)
				this.companyGroupManageable = true
			}
			if (this.isAdmin) {
				navT.splice(navT.length, 0,
					{
						component: 'CNavItem',
						name: 'menu.management.category',
						to: '/admin/category',
						icon: 'cil-star',
					},
					{
						component: 'CNavItem',
						name: 'menu.management.report',
						to: '/admin/report',
						icon: 'cil-description'
					},
					{
						component: 'CNavItem',
						name: 'menu.management.region',
						to: '/admin/region',
						icon: 'cil-compass',
					},
					{
						component: 'CNavItem',
						name: 'menu.management.group',
						to: '/admin/group',
						icon: 'cil-object-group',
					},
					{
						component: 'CNavItem',
						name: 'menu.management.elastic',
						to: '/admin/elastic',
						icon: 'cib-elastic'
					},
					{
						component: 'CNavTitle',
						name: 'Security',
					},
					{
						component: 'CNavItem',
						name: 'menu.security.user',
						to: '/security/users',
						icon: 'cil-people',
					}
				)
			}
			this.key++
			return navT
		},
	},
	setup () {
		const store = useStore()
		const key = ref(1)
		const companyGroupManageable = ref(false)
		return {
			logoNegative,
			sygnet,
			key,
			sidebarUnfoldable: computed(() => store.state.sidebarUnfoldable),
			sidebarVisible: computed(() => store.state.sidebarVisible),
			companyGroupManageable,
		}
	},
}
</script>
