import axios from 'axios'
import {getPaginationDetails, getDeleteOperation, getGetters} from "@/common/helper"

const state = {
    collection: {},
    item: {},
    loading: false,
    error: null,
}

const endpoint = '/api/region'

const mutations = {
    set: (state, [key, value]) => {
        state[key] = value
    },
}

const actions = {
    init({commit, rootState}, payload) {
        commit('set', ['item', {}])
    },
    get({commit, rootState}, payload) {
        commit('set', ['loading', true])

        return new Promise((resolve, reject) => {
            axios
                .get(endpoint + '/' + payload)
                .then(
                    (response) => {
                        commit('set', ['item', response.data])
                        resolve()
                    },
                    (error) => {
                        commit('set', ['error', error])
                        reject(error)
                    },
                )
                .finally(() => {
                    commit('set', ['loading', false])
                })
        })
    },
    getAll({commit, rootState}, payload) {
        commit('set', ['loading', true])

        return new Promise((resolve, reject) => {
            axios
                .get(endpoint, { params: payload })
                .then(
                    (response) => {
                        commit('set', ['collection', response.data])
                        resolve()
                    },
                    (error) => {
                        commit('set', ['error', error])
                        commit('set', ['collection', []])
                        reject(error)
                    },
                )
                .finally(() => {
                    commit('set', ['loading', false])
                })
        })
    },
    save({commit, rootState}, payload) {
        commit('set', ['loading', true])

        return new Promise((resolve, reject) => {
            if (state.item['@id']) {
                axios
                    .patch(state.item['@id'], state.item, {headers: {'Content-Type': 'application/merge-patch+json'}})
                    .then(
                        (response) => {
                            resolve(response)
                        },
                        (error) => {
                            commit('set', ['error', error])
                            reject(error)
                        },
                    )
                    .finally(() => {
                        commit('set', ['loading', false])
                    })
            } else {
                axios
                    .post(endpoint, state.item)
                    .then(
                        (response) => {
                            resolve(response)
                        },
                        (error) => {
                            commit('set', ['error', error])
                            reject(error)
                        },
                    )
                    .finally(() => {
                        commit('set', ['loading', false])
                    })
            }
        })
    },
    remove({commit, rootState}, payload) {
        return getDeleteOperation(endpoint, payload.id, commit)
    },
}

const getters = {
    ...getGetters(endpoint)
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}
