<template>
  <CFooter>
    <div class="ms-auto">
      <a href="https://allesregional.de" target="_blank">allesregional.de</a> ist eine Initiative der Passauer Neue Presse GmbH
      <span class="ms-1">&copy; {{ new Date().getFullYear() }}</span>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'AppFooter',
}
</script>
