import { buildRoutes } from "@/common/helper"
import store from '@/store'

const Region = () => import('@/views/region/Region')
const EditRegion = () => import('@/views/region/EditRegion')
const icon = 'cil-compass'

export default [
  {
    path: '/admin/region',
    name: 'Region',
    meta: {
      icon: icon,
    },
	beforeEnter: (async (to, from, next) =>
    {
      //get the isAdmin state from the authorization file in the store
      const isAdmin = await store.getters['authorization/isAdmin']

      if (!isAdmin)
      {
        next({ name: 'Region' })
      }
      next()
    }),
    component: Region,
  },
  {
    path: '/admin/region/new',
    name: 'Create Region',
    meta: {
      icon: icon,
    },
    beforeEnter: (async (to, from, next) =>
    {
      //get the isAdmin state from the authorization file in the store
      const isAdmin = await store.getters['authorization/isAdmin']

      if (!isAdmin)
      {
        next({ name: 'Region' })
      }
      next()
    }),
    component: EditRegion,
  },
  {
    path: '/admin/region/edit/:id',
    name: 'Edit Region',
    meta: {
      icon: icon,
    },
    beforeEnter: (async (to, from, next) =>
    {
      //get the isAdmin state from the authorization file in the store
      const isAdmin = await store.getters['authorization/isAdmin']

      if (!isAdmin)
      {
        next({ name: 'Region' })
      }
      next()
    }),
    component: EditRegion,
  },
]
