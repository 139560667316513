import { buildRoutes } from "@/common/helper"
import jwtDecode from "jwt-decode"



export default buildRoutes(
  '/admin',
  'offer',
  'cil-coffee',
  () => import('@/views/offer/Offer'),
  () => import('@/views/offer/EditOffer'),

)
