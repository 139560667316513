<template>
  <CFormLabel :for="'input_' + field" :class="`${labelClasses} col-form-label`"
              :style="`${labelAlignRight? 'text-align: right;': ''}`"
  >
    {{ $t(label) }} <CIcon name="cil-asterisk" size="sm" class="text-danger" v-if="required"/>
  </CFormLabel>
  <slot name="input">
    <CCol :class="inputClasses">
      <input class="form-control" :id="'input_' + field" :placeholder="placeholder" :type="type"
             :value="modelValue" @input="$emit('update:modelValue', $event.target.value)"
      />
    </CCol>
  </slot>
</template>

<script>
export default {
  name: "TextTypeInput",
  props: {
    labelClasses: {
      type: String,
      default: 'col-sm-2'
    },
    inputClasses: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    field: {
      type: String,
      required: true
    },
    modelValue: {
      type: String,
      required: true,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    labelAlignRight: {
      type: Boolean,
      default: false
    }
  }
}
</script>
