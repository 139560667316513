export const logoNegative = [
  '556 500',
  `
  <title>allesregional logo</title>
  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g class="ar-logo" transform="translate(0.000000, -1.000000)">
        <g class="ar-logo-bag" transform="translate(34.000000, 0.844000)">
            <polygon fill="#E95045" points="45.9483 184.8408 42.2543 150.1198 7.5333 153.8138 11.2263 188.5338 11.2273 188.5338 14.9213 223.2538 49.6413 219.5608" style="animation-delay: 1.4s;"></polygon>
            <polygon fill="#CFA9CF" points="91.75 285.3086 126.471 281.6156 88.056 250.5876" style="animation-delay: 4.6s;"></polygon>
            <polygon fill="#6E4F67" points="57.0284 289.002 91.7504 285.309 88.0554 250.588" style="animation-delay: 4.2s;"></polygon>
            <polygon fill="#4E754C" points="119.083 212.1738 88.056 250.5878 126.471 281.6148 122.776 246.8948" style="animation-delay: 0.8s;"></polygon>
            <polygon fill="#4A295D" points="49.6416 219.5606 88.0556 250.5876 119.0826 212.1736 84.3626 215.8676" style="animation-delay: 8.8s;"></polygon>
            <polygon fill="#E9515A" points="49.6416 219.5606 18.6146 257.9746 57.0286 289.0016 88.0556 250.5876" style="animation-delay: 6s;"></polygon>
            <polygon fill="#A91C3A" points="14.9209 223.2539 18.6139 257.9749 49.6419 219.5609" style="animation-delay: 7s;"></polygon>
            <polygon fill="#EA967B" points="53.335 254.2813 88.056 250.5883 49.642 219.5603" style="animation-delay: 8.2s;"></polygon>
            <polygon fill="#94C01F" points="148.8633 236.2266 148.7813 209.0136 148.6813 175.5276 119.0833 212.1736 122.7763 246.8946 126.4703 281.6156 148.9923 279.2186" style="animation-delay: 9.4s;"></polygon>
            <polygon fill="#F18F8D" points="80.669 181.1475 84.362 215.8675 115.39 177.4535" style="animation-delay: 5s;"></polygon>
            <polygon fill="#955573" points="115.3897 177.4531 84.3627 215.8671 119.0827 212.1741" style="animation-delay: 2s;"></polygon>
            <polygon fill="#A73680" points="115.3897 177.4531 76.9747 146.4271 45.9477 184.8411 84.3627 215.8671 80.6687 181.1471" style="animation-delay: 8.8s;"></polygon>
            <polygon fill="#CE0561" points="42.2539 150.1201 45.9469 184.8411 76.9749 146.4271" style="animation-delay: 3.4s;"></polygon>
            <polygon fill="#5D78AF" points="183.002 152.8809 217.723 149.1879 215.857 131.6529 212.164 96.9319 181.137 135.3459" style="animation-delay: 5s;"></polygon>
            <polygon fill="#4F8FCB" points="181.1368 135.3457 212.1638 96.9317 177.4438 100.6247" style="animation-delay: 3.2s;"></polygon>
            <polygon fill="#24305F" points="146.4161 139.0391 181.1371 135.3461 177.4431 100.6251 142.7231 104.3181 108.0021 108.0131" style="animation-delay: 5.8s;"></polygon>
            <polygon fill="#8FBA94" points="183.002 152.8809 181.137 135.3459 146.416 139.0389 115.39 177.4529 119.083 212.1739 148.682 175.5279 148.625 156.5369" style="animation-delay: 0.8s;"></polygon>
            <polygon fill="#A789AC" points="76.9746 146.4268 115.3896 177.4528 146.4156 139.0388" style="animation-delay: 8.4s;"></polygon>
            <polygon fill="#ABC2E5" points="108.002 108.0127 76.975 146.4267 146.416 139.0387" style="animation-delay: 6.4s;"></polygon>
            <polygon fill="#244A99" points="177.4434 100.625 212.1644 96.932 208.4704 62.211 173.7504 65.904 142.7224 104.318" style="animation-delay: 3.8s;"></polygon>
            <polygon fill="#0A8C91" points="108.002 108.0127 142.723 104.3187 139.029 69.5977 104.309 73.2907" style="animation-delay: 3.4s;"></polygon>
            <polygon fill="#38B5AA" points="108.002 108.0127 104.309 73.2907 69.588 76.9857 73.281 111.7057 76.975 146.4267" style="animation-delay: 6.8s;"></polygon>
            <polygon fill="#7E98C1" points="139.0293 69.5977 142.7223 104.3187 173.7503 65.9047 135.3363 34.8767" style="animation-delay: 5.2s;"></polygon>
            <polygon fill="#5567A8" points="173.75 65.9043 170.057 31.1833 135.336 34.8773" style="animation-delay: 5s;"></polygon>
            <polygon fill="#F29325" points="73.2813 111.7061 69.5883 76.9851 65.8943 42.2651 34.8673 80.6791 38.5603 115.3991" style="animation-delay: 1.8s;"></polygon>
            <polygon fill="#FABE69" points="65.8936 42.2647 31.1736 45.9577 34.8676 80.6787 65.8946 42.2647" style="animation-delay: 2.4s;"></polygon>
            <polygon fill="#FFDD14" points="131.6426 0.1563 96.9216 3.8493 62.2016 7.5433 65.8946 42.2633 65.8956 42.2633 100.6156 38.5703 135.3356 34.8773" style="animation-delay: 1.6s;"></polygon>
            <polygon fill="#85CEE3" points="170.0567 31.1836 131.6427 0.1566 135.3357 34.8766" style="animation-delay: 3.2s;"></polygon>
            <polygon fill="#FFEA7F" points="65.8946 42.2637 62.2016 7.5427 31.1736 45.9577 65.8936 42.2647" style="animation-delay: 8.8s;"></polygon>
            <polygon fill="#F8C4AF" points="42.2539 150.1192 7.5329 153.8122 45.9469 184.8412" style="animation-delay: 1.4s;"></polygon>
            <polygon fill="#E6344C" points="34.8672 80.6787 0.1462 84.3717 3.8402 119.0927 7.5332 153.8137 38.5602 115.3997" style="animation-delay: 5.4s;"></polygon>
            <polygon fill="#7D65A9" points="84.3604 215.8672 58.7524 195.1822 45.9484 184.8412 49.6414 219.5602" style="animation-delay: 1.2s;"></polygon>
            <polygon fill="#F1B600" points="7.5332 153.8135 42.2542 150.1205 38.5602 115.3995" style="animation-delay: 1.6s;"></polygon>
            <polygon fill="#7A0041" points="73.2813 111.7061 38.5603 115.3991 42.2543 150.1201 76.9743 146.4271" style="animation-delay: 1.8s;"></polygon>
        </g>
        <g class="ar-logo-border" transform="translate(0.000000, 44.000000)" fill="#FEFEFE">
            <polygon points="278.0679 0.3889 278.0679 10.3889 449.4549 10.3889 449.4549 84.9969 449.4549 86.0679 459.4549 86.0679 459.4549 83.9329 459.4549 0.3889"></polygon>
            <polygon points="459.4546 459.844 -0.0004 459.844 -0.0004 0.389 34.1466 0.389 34.1466 10.389 9.9996 10.389 9.9996 449.844 449.4546 449.844 449.4546 364.926 459.4546 364.926"></polygon>
        </g>
        <g class="ar-logo-text" transform="translate(57.000000, 146.000000)" fill="#FEFEFE">
            <path d="M174.6914,94.8303 L172.8174,84.5553 L165.0154,42.0823 L165.3674,51.2673 C165.6604,58.4283 165.8704,64.8143 165.9464,66.9963 C165.9694,73.0903 166.1304,79.0143 166.3074,85.0923 L166.4994,95.7013 L174.6914,94.8303 Z M178.7734,114.0943 L166.8704,115.3593 L166.4234,130.2583 L144.9364,132.5453 L148.4994,27.8943 L178.1774,24.7363 L203.5224,126.3123 L181.5714,128.6463 L178.7734,114.0943 Z"></path>
            <polygon points="198.7373 22.5491 220.8433 20.1971 229.6583 103.0531 252.3803 100.6351 254.5343 120.8851 209.7063 125.6541"></polygon>
            <polygon points="251.7574 16.9085 273.8624 14.5565 282.6774 97.4125 305.4004 94.9945 307.5544 115.2445 262.7264 120.0135"></polygon>
            <polygon points="304.7769 11.2688 350.2239 6.4338 352.3609 26.5298 329.1749 28.9968 330.8839 45.0728 353.1439 42.7048 355.2319 62.3358 332.9719 64.7038 335.8669 91.9108 359.9819 89.3458 362.1189 109.4418 315.7459 114.3728"></polygon>
            <path d="M388.5015,69.428 L389.2575,76.539 C390.2435,85.813 391.9465,88.602 396.4315,88.125 C400.6045,87.682 402.2845,84.377 401.6265,78.194 C400.7715,70.155 399.1495,68.139 386.1945,59.512 C368.5175,47.635 363.2945,41.155 362.0285,29.253 C360.3025,13.023 369.2245,2.85 386.6925,0.991 C399.2125,-0.341 409.1185,3.139 413.1555,10.213 C415.2675,13.898 415.7395,15.41 416.8805,24.668 C417.4315,28.363 417.4315,28.363 417.5465,29.445 L395.9035,31.746 C395.8945,30.184 395.8115,29.411 395.6965,28.329 C395.0895,22.61 392.7495,19.733 389.0405,20.127 C386.1035,20.44 384.0645,23.313 384.3945,26.405 C384.5735,28.106 385.7655,30.481 387.3535,32.188 C389.2825,34.17 389.2825,34.17 400.4075,41.741 L403.6225,44.057 C412.0425,49.727 414.4365,51.661 417.5265,55.71 C420.6145,59.758 422.8845,66.395 423.7065,74.125 C425.7945,93.756 417.1575,105.149 398.4525,107.139 C388.8685,108.158 380.8605,106.354 375.5245,101.762 C372.4315,99.119 370.2865,95.127 369.1425,90.246 C368.6515,88.578 368.3545,85.797 367.6325,78.994 L366.8585,71.729 L388.5015,69.428 Z"></path>
            <path d="M30.5625,211.7151 C35.9735,211.1391 38.1505,208.0941 37.5425,202.3751 C36.8185,195.5721 34.2035,193.0371 28.9475,193.5961 L24.1555,194.1061 L26.0795,212.1921 L30.5625,211.7151 Z M0.0285,177.4441 L31.7175,174.0721 C48.5665,172.2791 56.4995,179.2521 58.4725,197.8031 C60.0845,212.9511 57.9265,219.1211 50.3955,221.7991 C56.8805,222.5161 59.8635,227.0451 60.8115,237.4181 L64.7905,274.8261 L42.6855,277.1781 L38.7555,240.2351 C37.8835,232.0411 35.7655,229.7641 29.8915,230.3891 C29.5825,230.4221 28.8095,230.5041 28.0535,230.7401 L33.1015,278.1971 L10.9965,280.5491 L0.0285,177.4441 Z"></path>
            <polygon points="65.7237 170.4553 111.1707 165.6213 113.3087 185.7173 90.1207 188.1843 91.8307 204.2603 114.0907 201.8913 116.1797 221.5233 93.9197 223.8913 96.8137 251.0983 120.9287 248.5313 123.0667 268.6273 76.6917 273.5603"></polygon>
            <path d="M180.0928,199.2464 L186.7528,261.8514 L178.2498,262.7584 L174.9158,256.3884 C170.2348,262.3594 164.0378,265.8324 156.0008,266.6874 C147.6538,267.5764 140.1978,265.0864 135.3988,259.6544 C131.1468,254.9494 128.9588,249.0844 127.9228,239.3464 L123.2028,194.9804 C121.8228,181.9964 123.5228,174.4694 129.2838,168.3834 C133.7868,163.6834 139.6018,161.0334 148.1048,160.1274 C158.4608,159.0254 167.0158,161.5554 172.1568,167.2624 C175.7088,171.2624 177.9278,177.4354 178.9148,186.7094 C179.0958,188.4104 179.2598,189.9554 179.3858,192.6004 L157.8988,194.8874 L157.7668,193.6484 L156.9608,186.0744 C156.3688,180.5104 153.8928,177.8034 150.0288,178.2154 C148.0188,178.4284 146.1238,179.7254 145.3778,181.5234 C144.5978,183.0124 144.6078,184.5744 145.2168,190.2954 L150.0018,235.2774 C150.5438,240.3794 150.8468,241.7544 151.6458,243.3884 C152.6658,245.6254 155.3198,247.0624 157.6388,246.8144 C161.6578,246.3874 163.0778,243.5784 162.4048,237.2404 L160.6608,220.8554 L154.6328,221.4964 L152.5768,202.1744 L180.0928,199.2464 Z"></path>
            <polygon points="187.8399 157.4651 210.1009 155.0961 221.0679 258.2011 198.8089 260.5701"></polygon>
            <path d="M258.4351,178.8753 C257.4801,169.9103 255.8101,167.4303 251.4821,167.8913 C246.8451,168.3833 245.6111,171.4863 246.5331,180.1433 L251.1031,223.1153 C251.6791,228.5253 252.0151,230.2093 252.9841,231.9823 C254.1751,234.3573 256.1791,235.5513 258.6521,235.2873 C260.6621,235.0743 262.1091,233.9823 262.8721,232.3383 C263.7571,230.3673 263.7461,228.8053 262.9571,221.3853 L258.4351,178.8753 Z M224.2631,180.9473 C222.2731,162.2423 230.9581,151.3143 249.2011,149.3733 C267.9041,147.3833 278.3691,156.1193 280.3741,174.9793 L286.0151,228.0003 C287.6111,242.9943 277.6561,253.7463 260.3411,255.5883 C243.1831,257.4123 231.4641,248.6543 229.7551,232.5783 L224.2631,180.9473 Z"></path>
            <path d="M355.5542,243.8968 L332.2122,246.3788 L314.3802,190.4338 C314.9132,193.9728 315.3292,196.4298 315.6162,197.6488 C316.2882,201.0178 316.9582,204.3848 317.4762,207.7698 C317.7942,209.2988 318.2942,212.5298 318.6712,216.0848 L322.0092,247.4648 L299.9042,249.8168 L288.9372,146.7088 L312.4332,144.2108 L329.7452,193.8008 C329.2302,190.4178 328.8122,187.9608 328.5422,186.8948 C326.0622,173.8718 325.8102,172.9588 325.3492,168.6308 L322.6362,143.1258 L344.5852,140.7918 L355.5542,243.8968 Z"></path>
            <path d="M393.3453,205.3264 L391.4703,195.0534 L383.6693,152.5784 L384.0213,161.7634 C384.3143,168.9244 384.5253,175.3104 384.6013,177.4924 C384.6223,183.5864 384.7843,189.5104 384.9623,195.5884 L385.1523,206.1974 L393.3453,205.3264 Z M397.4273,224.5904 L385.5253,225.8554 L385.0773,240.7564 L363.5893,243.0414 L367.1543,138.3884 L396.8313,135.2324 L422.1773,236.8084 L400.2263,239.1424 L397.4273,224.5904 Z"></path>
            <polygon points="417.3921 133.0452 439.4971 130.6932 448.3101 213.5492 471.0351 211.1312 473.1891 231.3812 428.3591 236.1502"></polygon>
        </g>
    </g>
</g>
`,
]
