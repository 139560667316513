<template>
  <CButtonGroup class="d-flex mt-1">
    <CButton type="button" variant="ghost" color="primary" @click="$emit('edit')"
             size="sm" class="p-1">
      <CIcon name="cil-pencil" size="sm"/>
    </CButton>
    <CButton type="button" variant="ghost" color="danger" @click="$emit('delete')"
             size="sm" class="p-1">
      <CIcon name="cil-trash" size="sm"/>
    </CButton>
  </CButtonGroup>
</template>

<script>
export default {
  name: "MediaActions",
  emits: ['edit', 'delete']
}
</script>

<style scoped>

</style>
