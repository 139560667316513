import { h, resolveComponent } from 'vue'
import { createRouter, createWebHashHistory } from 'vue-router'
import store from '../store'

import DefaultLayout from '@/layouts/DefaultLayout'

const Login = () => import('@/views/pages/Login')
const Page404 = () => import('@/views/pages/Page404')

const Dashboard = () =>
	import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue')

const User = () => import('@/views/user/Users')
const EditUser = () => import('@/views/user/EditUser')
import Category from '@/router/modules/category'
import ContentElement from '@/router/modules/content_element'
import Company from '@/router/modules/company'
import Location from '@/router/modules/location'
import Offer from '@/router/modules/offer'
import Region from '@/router/modules/region'
import Group from '@/router/modules/group'
import Event from '@/router/modules/event'
const Report = () => import('@/views/report/Report')
const EditProfile = () => import('@/views/user/EditProfile')

const routes = [
	{
		path: '/',
		component: {
			render () {
				return h(resolveComponent('router-view'))
			},
		},
		children: [
			{
				path: 'login',
				name: 'Login',
				component: Login,
			},
			{
				path: 'logout',
				name: 'Logout',
				beforeEnter: (to, from, next) => {
					store.dispatch('authorization/logout')
					next({ name: 'Login' })
				}
			},
			{
				path: '404',
				name: 'Page404',
				component: Page404,
			},
			{ path: '/:pathMatch(.*)*', component: Page404 },
		],
	},
	{
		path: '/',
		component: DefaultLayout,
		redirect: '/dashboard',
		children: [
			{
				path: '/dashboard',
				name: 'Dashboard',
				component: Dashboard,
			},
		],
	},
	// {
	// 	path: '/user/profile',
	// 	component: DefaultLayout,
	// 	redirect: '/user/profile',
	// 	children: [
	// 		{
	// 			path: '/user/profile',
	// 			name: 'Profile',
	// 			component: EditProfile,
	// 		},
	// 	],
	// },
	{
		path: '/security',
		name: 'Security',
		meta: {
			icon: 'cil-lock-locked'
		},
		redirect: '/security/users',
		component: DefaultLayout,
		icon: 'cil-people',
		children: [
			{
				path: '/security/users',
				name: 'Users',
				meta: {
					icon: 'cil-people',
				},
				component: User,
			},
			{
				path: '/security/users/new',
				name: 'Create User',
				meta: {
					icon: 'cil-people',
				},
				component: EditUser,
			},
			{
				path: '/security/users/edit/:id',
				name: 'Edit User',
				meta: {
					icon: 'cil-people',
				},
				component: EditUser,
			},
		],
		beforeEnter: (async (to, from, next) => {
			//get the isAdmin state from the authorization file in the store
			const isAdmin = await store.getters['authorization/isAdmin']

			if (!isAdmin) {
				next({ name: 'Dashboard' })
			}
			next()
		}),
	},
	{
		path: '/admin',
		name: 'Administration',
		meta: {
			icon: 'cil-lock-locked'
		},
		redirect: '/admin/category',
		component: DefaultLayout,
		icon: 'cil-star',
		children: [
			{
				path: '/admin/media',
				name: 'Medienverwaltung',
				meta: {
					icon: 'cil-library',
				},
				component: () => import('@/views/media/Media'),
			},
			{
				path: '/admin/report',
				name: 'Berichte',
				meta: {
					icon: 'cil-description',
				},
				beforeEnter: (async (to, from, next) => {
					//get the isAdmin state from the authorization file in the store
					const isAdmin = await store.getters['authorization/isAdmin']

					if (!isAdmin) {
						next({ name: 'Dashboard' })
					}
					next()
				}),
				component: Report,
			},
			// {
			//   path: '/admin/elastic',
			//   name: 'Elasticsearch',
			//   meta: {
			//     icon: 'cib-elastic',
			//   },
			//   component: () => import('@/views/elastic/Elastic'),
			// },
			...Category,
			...Company,
			...Location,
			...Offer,
			...ContentElement,
			...Region,
			...Group,
			...Event,
		],
	},
	{ path: '/:pathMatch(.*)*', component: Page404 },
]

const router = createRouter({
	history: createWebHashHistory(process.env.BASE_URL),
	routes,
	scrollBehavior () {
		// always scroll to top
		return { top: 0 }
	},
})

const companyDependantRoutes = [
	'Offer',
	'Location',
	'Create Location',
	'Edit Location',
	'Create Offer',
	'Edit Offer',
	'News',
	'Create News',
	'Edit News',
	'Content Element',
	'Create Content Element',
	'Edit Content Element',
]

router.beforeEach((to, from) => {
	if (!store.getters['authorization/getAuthorized'] && to.name !== 'Login') {
		let auth = sessionStorage.getItem('authorization')
		if (auth) {
			// auth will contain the jwt token and refresh token
			auth = JSON.parse(auth)
			store.commit('authorization/setAuthorization', auth)
			store.dispatch('authorization/checkAuthorization')

		} else {
			return { name: 'Login' }
		}
		if (to.name !== 'Dashboard') {
			if (companyDependantRoutes.includes(to.name) && !store.getters['getCompany']) {
				return { name: 'Dashboard' }
			}
		}
	}
})

export default router
