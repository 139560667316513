<template>
  <CModal :visible="visible" @close="$emit('close')" alignment="center" size="xl">
    <CModalHeader class="bg-primary">
      <CModalTitle class="text-white">
        {{ $t('titles.mediaSelection') }}
      </CModalTitle>
    </CModalHeader>
    <CModalBody class="p-0">
      <MediaNavigation media-selection @select="$emit('select', $event)"/>
    </CModalBody>
    <CModalFooter>
      <CButton color="secondary" @click="$emit('close')">
        Close
      </CButton>
    </CModalFooter>
  </CModal>
</template>

<script>
import MediaNavigation from "@/views/media/MediaNavigation";
export default {
  name: "MediaSelectionModal",
  components: {MediaNavigation},
  props: {
    visible: {
      type: Boolean,
      required: true
    }
  },
  emits: ['select']
}
</script>

<style>
div.sidebar.sidebar-fixed {
  z-index: 0
}
</style>
