<template>
  <CRow class="animate__animated animate__fadeIn">
    <CCol :xs="12">
      <CCard>
        <CCardHeader class="d-flex align-items-center justify-content-sm-between">
          <slot name="title">
            <div />
          </slot>
          <CButtonGroup>
            <slot name="commands">
            </slot>
          </CButtonGroup>
        </CCardHeader>
        <CCardBody class="p-0">
          <Transition name="fade" mode="out-in" @after-enter="$emit('after-reload')">
            <span v-if="loading" class="p-4 d-flex align-items-center" style="font-size: 1.5em">
              <LoadingAnimation /> <span class="ms-3">Super Daten werden geladen ...</span>
            </span>
            <div v-else>
              <slot></slot>
            </div>
          </Transition>
        </CCardBody>
        <CCardFooter v-if="$slots.footer">
          <slot name="footer"></slot>
        </CCardFooter>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import LoadingAnimation from "@/common/LoadingAnimation";
export default {
  name: "Module",
  components: {LoadingAnimation},
  props: {
    loading: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>

<style scoped>

</style>
